import React from 'react'
import { Layout } from '../components'
import './about.scss'

const About = () => {
  return (
    <Layout>
      <div className="section-about">
        <div className="section-inner section-inner--s vertical-indent--m">
          <div className="section-about__container">
            <h1 className="regular">Привет 👋</h1>
            <div className="section-about__description">
              Наша методика курсов не только уникальна и эффективна, но еще и супер увлекательна. Наши амбиции - это
              создать практичную среду для обучения коммуникационным навыкам. Мы верим, что практичный подход - это ключ
              к успеху в риторике.
            </div>
            <div className="section-about__description">
              На нашем сайте вы освоите и научитесь применять традиционные и современные приёмы риторики для
              выступлений, презентаций, видеоблогинга, стендапов и многого другого. 🔥
            </div>
          </div>
          <div className="section-about__container">
            <h1 className="regular">О команде</h1>
            <div className="section-about__description">
              Сервис «Точка речи» создан небольшой командой профессионалов, с богатым опытом работы в сфере
              информационных технологий а также в сфере обучения. Встречайте:{' '}
            </div>
            <div className="section-about__team-dude">
              <div className="section-about__dude-name">Анатолий Иванов 🦸‍♂️</div>
              <div className="section-about__dude-description">
                Гуру риторики, профессионал выступлений и супер менеджер
              </div>
            </div>
            <div className="section-about__team-dude">
              <div className="section-about__dude-name">Виталий Иванов 👷 </div>
              <div className="section-about__dude-description">
                Эксперт высоких технологий, умеет читать (и писать) код матрицы
              </div>
            </div>
            <div className="section-about__team-dude">
              <div className="section-about__dude-name">Руслан Креймер 🧑‍🎨</div>
              <div className="section-about__dude-description">Креативный генератор и дизайнер решений</div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default About
